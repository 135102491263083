<template>
  <Modal ref="modal" title="Редактирование заморозки" size="lg" v-if="show" @closed="close">
    <template v-slot:body>
      <template v-for="s in snapshots">
        <div class="custom-control custom-radio" :key="s.Id">
          <input type="radio" class="custom-control-input" :id="`snapshot-${s.Id}`" :value="s.Id" v-model="itemId">
          <label class="custom-control-label" :for="`snapshot-${s.Id}`">
            <template v-if="s.Type === constants.TYPE_PURCHASE">
              Заморозка закупки ({{s.CreatedAt|moment}}, {{s.CreatedByName}})
            </template>
            <template v-else-if="s.Type === constants.TYPE_RESERVATION">
              Счёт для брони &laquo;{{s.ReservationName}}&raquo; ({{s.CreatedAt|moment}}, {{s.CreatedByName}})
            </template>
          </label>
        </div>
      </template>
      <hr>
      <template v-if="itemId">
        <SnapshotItemEditor :purchase="purchase" :snapshot="snapshot" :article-id="articleId" :volume="volume" @updated="close"/>
      </template>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/Modal'
  import Api from '@/api'
  import find from 'lodash/find'
  import SnapshotItemEditor from '@/components/SnapshotEditor/SnapshotItemEditor'

  // Purchase snapshot.
  const TYPE_PURCHASE = 0
  // Reservation snapshot.
  const TYPE_RESERVATION = 1

  export default {
    components: {SnapshotItemEditor, Modal},
    props: ['purchase'],
    data() {
      return {
        show: false,
        articleId: null,
        volume: 1,
        itemId: null,

        snapshots: [],
      }
    },

    computed: {
      constants() {
        return {
          'TYPE_PURCHASE': TYPE_PURCHASE,
          'TYPE_RESERVATION': TYPE_RESERVATION,
          'TYPE_LABELS': {
            [TYPE_PURCHASE]: 'Заморозка покупки',
            [TYPE_RESERVATION]: 'Заморозка брони',
          }
        }
      },

      snapshot() {
        if(this.itemId === null) {
          return null
        }

        return find(this.snapshots, {Id: this.itemId})
      }
    },

    methods: {
      open({articleId, volume}) {
        this.show = true
        this.articleId = articleId
        this.volume = volume
        this.itemId = null

        this.fetchSnapshots().then(snapshots => {
          if(snapshots.length > 0) {
            this.itemId = snapshots[0].Id
          } else {
            this.itemId = null
          }
        })
      },

      fetchSnapshots() {
        return Api.get(`/Purchases/${this.purchase.Id}/Items/${this.articleId}/Snapshots`).then(r => {
          this.snapshots = r.data.Snapshots

          return this.snapshots
        }).catch(()/*e*/ => {
          // FIXME
        })
      },

      close() {
        this.$emit('updated')
        this.show = false
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
