<template>
  <div>
    <div class="input-group input-group-sm d-sm-inline-flex flex-nowrap" style="width: initial;">
      <div class="input-group-prepend">
        <label :for="`purchaseExchangeRate${cid}`" class="input-group-text">1 RUB =</label>
      </div>
      <input :id="`purchaseExchangeRate${cid}`" type="number"  step="any" class="form-control text-right" style="min-width: 7rem;"
             placeholder="Стоимость 1 RUB в BYN" v-model.trim="rate">
      <div class="input-group-append">
        <label :for="`purchaseExchangeRate${cid}`" class="input-group-text">{{currencyCode}}</label>
        <button class="btn btn-primary" type="button" @click="$emit('input', rate)">{{btnLabel}}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import uniqueId from 'lodash/uniqueId'

  export default {
    name: 'ExchangeRateControl',

    props: {
      value: String,
      currencyCode: String,
      btnLabel: {
        type: String,
        default: 'Обновить',
      },
    },

    watch: {
      // Sync the upstream value down to the local rate.
      value() {
        this.rate = this.value
      }
    },

    data() {
      return {
        cid: uniqueId(),
        rate: this.value,
      }
    },
  }
</script>

<style scoped>

</style>
