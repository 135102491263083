<template>
  <div>
    <h1>
      <router-link class="back-button" :to="{ name: 'purchase', params: { id: id }}">&larr;</router-link>
      {{' '}}
      Настройки <small class="text-muted font-weight-light">{{purchase.Name}}</small>
    </h1>

    <hr>

    <div class="setting-group">
      <div class="col-setting">
        <button type="button" class="btn btn-warning btn-sm" @click="handleSendPurchase">Отправить на Сима-ленд</button>
        <template v-if="loading.sendPurchase">
          {{' '}}
          <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
        </template>
        <template v-if="loaded.sendPurchase">
          {{' '}}
          <span class="result-msg text-success">Товары отправлены.</span>
        </template>
        <template v-else-if="error.sendPurchase">
          {{' '}}
          <span class="result-msg text-danger">Ошибка при отправке товаров. {{error.sendPurchase}}</span>
        </template>
      </div>
      <div class="col-help d-none">
        <p class="help-description">
          В корзину Сима-ленд будут отправлены только товары в наличии и собранные ряды.
          Если в ряду собрано минимальное количество, но не кратное (по 20 шт, по 30 шт и т. п.),
          то ряд будет округлён до следующего полного кратного (например, если по 20 шт и собрано
          36 шт, то будет округлено до 40 шт).
        </p>
      </div>
    </div>

    <FillCartReport ref="fillCartReport"/>

    <div class="setting-group">
      <div class="col-setting">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="chkIsPaidDelivery" :checked="purchase.IsPaidDelivery"
                 @change="handleTogglePaidDelivery">
          <label class="custom-control-label" for="chkIsPaidDelivery">Расчёт доставки</label>
          <template v-if="loading.togglePaidDelivery">
            {{' '}}
            <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
          </template>
        </div>
        <template v-if="error.togglePaidDelivery">
          {{' '}}
          <span class="result-msg text-danger">Ошибка. {{error.togglePaidDelivery}}</span>
        </template>
      </div>
      <div class="col-help d-none">
        <p class="help-description">
          При включенном расчёте доставки участникам в счёт включается оплата доставки за все товары.
          Если расчёт доставки выключен, участникам выставляется счёт только за платную доставку.
        </p>
      </div>
    </div>

    <div class="setting-group">
      <div class="col-setting">
        <label>Город доставки:</label>
        <SimaSettlementSelector :id="purchase.SettlementId" @updated="handleSettlement"/>
        <template v-if="loading.settlement">
          {{' '}}
          <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
        </template>
        <template v-else-if="error.settlement">
          {{' '}}
          <span class="result-msg text-danger">{{error.settlement}}</span>
        </template>
      </div>
      <div class="col-help"></div>
    </div>

    <div class="setting-group">
      <div class="col-setting">
        <div>
          <label class="mb-1" for="snapshotSelector">Показывать участникам цены:</label>
        </div>
        <div class="input-group input-group-sm d-sm-inline-flex" style="width: initial;">
          <select id="snapshotSelector" class="custom-select" v-model="purchase.SnapshotId"
                  @change="handleChangeSnapshotId">
            <option value="00000000-0000-0000-0000-000000000000">Текущие с Сима-ленд</option>
            <option :value="s.Id" v-for="s in snapshots" :key="s.Id">Сделанные {{s.CreatedAt | moment}}
              ({{s.CreatedByName}})
            </option>
          </select>
          <div class="input-group-append">
            <button type="button" class="btn btn-info" @click="handleDoSnapshot">Заморозить цены</button>
          </div>
        </div>
        <template v-if="loading.snapshots">
          {{' '}}
          <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
        </template>
        <template v-if="error.snapshots">
          {{' '}}
          <span class="result-msg text-danger">Ошибка. {{error.snapshots}}</span>
        </template>
      </div>
      <div class="col-help d-none">
        <p class="help-description">
          Цены на Сима-ленд постоянно меняются. Данная функция позволяет заморозить цены в один момент
          для простоты расчётов. Выбор заморозки осуществляется из выпадающего списка.
        </p>
      </div>
    </div>

    <div class="setting-group">
      <div class="col-setting">
        <div class="mb-2">
          <button type="button" class="btn btn-sm"
                  :class="{ 'btn-danger': purchase.IsOpen, 'btn-success': !purchase.IsOpen }"
                  @click="handleOpenClose">{{purchase.IsOpen? 'Закрыть закупку' : 'Открыть закупку'}}
          </button>
          <template v-if="loading.openClose">
            {{' '}}
            <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
          </template>
          <template v-if="error.openClose">
            {{' '}}
            <span class="result-msg text-danger">Ошибка при изменении. {{error.openClose}}</span>
          </template>
        </div>
        <template v-if="!purchase.IsOpen">
          {{' '}}
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="hidePurchase" :checked="purchase.IsHidden" @change="handleChangeIsHidden">
            <label class="custom-control-label" for="hidePurchase">Скрыть закупку от участников</label>
          </div>
        </template>
      </div>
    </div>

    <!-- TODO: Honor legacy order tracking system. -->
    <div class="setting-group" v-if="!purchase.IsOpen">
      <div class="col-setting">
        <label class="d-block mb-1" for="simaInvoiceUpload">Загрузите XLS-файлы счетов:</label>
        <div class="input-group input-group-sm d-sm-inline-flex" style="width: initial;">
          <div class="custom-file" style="font-size:.875rem;">
            <input type="file" multiple accept=".xls" class="custom-file-input" id="simaInvoiceUpload"
                   name="invoiceFile" @change="updateInvoiceFiles">
            <label class="custom-file-label" for="simaInvoiceUpload">
              <template v-if="invoiceFiles.length > 0">
                {{invoiceFiles.length|plural('Выбран %v файл', 'Выбрано %v файла', 'Выбрано %v файлов')}}
              </template>
              <template v-else>
                Выберите файлы
              </template>
            </label>
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" @click="handleInvoiceUpload">Загрузить</button>
            <button type="button" class="btn btn-warning" @click="handleInvoiceDelete">Удалить все</button>
          </div>
        </div>
      </div>
    </div>

    <div class="setting-group">
      <div class="col-setting wide">
        <template v-if="loading.invoices">
          <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
        </template>
        <template v-else-if="error.invoices">
          <span class="result-msg text-danger">Ошибка при показе списка счетов. {{error.invoices}}</span>
        </template>
        <template v-else-if="purchase.HasInvoices && invoices.length > 0">
          <ul style="list-style-type: none; padding: 0;margin: 0;">
            <li v-for="i in invoices" :key="i.Id">
              <fa icon="file-excel" style="color:#1d6f42;"/>
              {{i.Name}}
              <small class="text-muted">
                ({{i.CreatedAt | moment}}, {{i.CreatedByName}})
              </small>
            </li>
          </ul>
        </template>
      </div>
    </div>

    <div class="setting-group">
      <div class="col-setting">
        <h3>Курс</h3>


        <div class="d-flex align-items-center">
          <ExchangeRateControl :currency-code="purchase.CurrencyCode"
                               :loading="loading.exchangeRate" :error="error.exchangeRate"
                               @input="handleExchangeRate"
                               v-model="purchase.ExchangeRate"/>
          <template v-if="loading.exchangeRate">
            <div class="spinner-border ml-2" style="width:1rem;height:1rem;"></div>
          </template>
          <template v-else-if="error.exchangeRate">
            <div class="result-msg ml-2 text-danger">{{error.exchangeRate}}</div>
          </template>
        </div>
      </div>
    </div>

    <div class="setting-group">
      <div class="col-setting">
        <a :href="`/orders/${id}/billDiscrepancies`" class="btn btn-sm btn-success mr-2" v-if="purchase.HasInvoices">Расхождения
          с закупкой</a>
        <a :href="`/orders/${id}/exportBillSummary`" class="btn btn-sm btn-success" target="_blank" @click="logSummary">
          <fa icon="file-excel"/>
          Сводка по участникам</a>
      </div>
    </div>

    <div class="setting-group">
      <div class="col-setting wide">
        <h3>Оптовые цены для участников</h3>
        <p class="form-text text-muted">
          Выберите оптовые категории, товары в которых отпускаются участникам по оптовым ценам.
        </p>

        <WholesaleCategories :wc="wholesaleCategories" :purchase-wc="purchaseWc"
                             @change="handleWholesaleCategories"
                             @change-wc="updateWholesaleCategory"
                             v-model="purchase.WholesaleCategories" v-if="wholesaleCategories.length > 0"/>

        <template v-if="loading.wholesaleCategories">
          <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
        </template>
        <template v-else-if="error.wholesaleCategories">
          <div class="result-msg text-danger">{{error.wholesaleCategories}}</div>
        </template>
      </div>
    </div>

    <div class="setting-group">
      <div class="col-setting wide">
        <template v-if="loading.participants">
          <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
        </template>
        <template v-else-if="error.participants">
          <div class="result-msg text-danger">{{error.participants}}</div>
        </template>
        <table class="table table-sm table-striped" v-else-if="loaded.participants">
          <thead>
            <tr>
              <th>Участник</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ParticipantRow :purchase="purchase" :purchase-participant="pp" @updated="refreshParticipants"
              v-for="pp in participants" :key="pp.Id"/>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import Api from '@/api'
  import ParticipantRow from '@/components/Settings/ParticipantRow'
  import forEach from 'lodash/forEach'
  import filter from 'lodash/filter'
  import userMixin from '@/util/mixin-user'
  import {purchase as initPurchase} from '@/util/defaults'
  import SimaSettlementSelector from '@/components/SimaSettlementSelector/SimaSettlementSelector'
  import FillCartReport from '@/components/FillCartReport'
  import WholesaleCategories from "@/components/Settings/WholesaleCategories";
  import ExchangeRateControl from "@/components/ExchangeRateControl";
  import {
    ACTION_FILL_SIMA_CART,
    ACTION_INVOICE_UPLOAD, ACTION_PARTICIPANTS_SUMMARY,
    ACTION_PURCHASE_HIDE,
    ACTION_SNAPSHOT_CREATE,
    logFeatureUse
  } from '@/util/ga'

  export default {
    name: "PurchaseSettings",

    components: {ExchangeRateControl, WholesaleCategories, FillCartReport, SimaSettlementSelector, ParticipantRow},

    props: ['id'],

    mixins: [userMixin,],

    data() {
      return {
        loading: {
          purchase: false,
          sendPurchase: false,
          togglePaidDelivery: false,
          snapshots: false,
          openClose: false,
          invoices: false,
          wholesaleCategories: false,
          participants: false,
          exchangeRate: false,
          settlement: false,
        },
        loaded: {
          purchase: false,
          sendPurchase: false,
          togglePaidDelivery: false,
          snapshots: false,
          openClose: false,
          invoices: false,
          wholesaleCategories: false,
          participants: false,
          exchangeRate: false,
        },
        error: {
          purchase: null,
          sendPurchase: null,
          togglePaidDelivery: null,
          snapshots: null,
          openClose: null,
          invoices: null,
          wholesaleCategories: null,
          participants: null,
          exchangeRate: null,
          settlement: null,
        },

        purchase: initPurchase,
        snapshots: [],

        prevSnapshotId: '00000000-0000-0000-0000-000000000000',

        // Populated with files picked via Upload dialog.
        invoiceFiles: [],

        // Filled via API.
        invoices: [],

        // Filled via API.
        wholesaleCategories: [],
        prevWholesaleCategories: [],

        // Filled via API.
        purchaseWc: [],

        // Filled via API.
        participants: [],
      }
    },

    created() {
      this.fetchPurchase(this.id)
    },

    watch: {
      'purchase.SnapshotId': function (val, prev) {
        this.prevSnapshotId = prev
      },
      'purchase.WholesaleCategories': function (val, prev) {
        this.prevWholesaleCategories = prev
      }
    },

    methods: {
      fetchPurchase(pid) {
        this.loaded.purchase = false
        this.loading.purchase = true
        this.error.purchase = null
        Api.get(`/Purchases/${pid}/Metadata`).then(r => {
          this.purchase = r.data
          this.loading.purchase = false
          this.loaded.purchase = true

          if (this.purchase.SourceId > 0) {
            return this.fetchWholesaleCategories()
          }
        }).catch(e => {
          this.loading.purchase = false
          this.error.purchase = e.message
        })

        this.fetchSnapshots()

        this.fetchInvoices()

        Api.get(`/Purchases/${pid}/WholesaleCategories`).then(r => {
          this.purchaseWc = r.data
        }).catch(e => {
          this.error.wholesaleCategories = e.message
        })

        this.loaded.participants = false
        this.loading.participants = true
        this.error.participants = null
        Api.get(`/Purchases/${pid}/Participants`).then(r => {
          this.participants = r.data
          this.loading.participants = false
          this.loaded.participants = true
        }).catch(e => {
          this.loading.participants = false
          this.error.participants = e.message
        })
      },

      fetchSnapshots() {
        this.loaded.snapshots = false
        this.loading.snapshots = true
        this.error.snapshots = null

        return Api.get(`/Purchases/${this.id}/Snapshots`).then(r => {
          this.snapshots = r.data.Snapshots
          this.loading.snapshots = false
          this.loaded.snapshots = true
        }).catch(e => {
          this.loading.snapshots = false
          this.error.snapshots = e.message
        })
      },

      fetchInvoices() {
        this.loaded.invoices = false
        this.loading.invoices = true
        this.error.invoices = null

        return Api.get(`/Purchases/${this.id}/Invoices`).then(r => {
          this.invoices = r.data.Items
          this.loading.invoices = false
          this.loaded.invoices = true
        }).catch(e => {
          this.loading.invoices = false
          this.error.invoices = e.message
        })
      },

      fetchWholesaleCategories() {
        this.loaded.wholesaleCategories = false
        this.loading.wholesaleCategories = true
        this.error.wholesaleCategories = null


        return Api.get(`/WholesaleCategories?SourceId=${this.purchase.SourceId}`).then(r => {
          this.wholesaleCategories = r.data
          this.loading.wholesaleCategories = false
          this.loaded.wholesaleCategories = true
        }).catch(e => {
          this.loading.wholesaleCategories = false
          this.error.wholesaleCategories = e.message
        })
      },

      updateInvoiceFiles(e) {
        this.invoiceFiles = e.target.files
      },

      handleSendPurchase() {
        this.loading.sendPurchase = true
        this.loaded.sendPurchase = false
        this.error.sendPurchase = null

        Api.post(`/SendPurchase`, {Id: this.id})
          .then(r => {
            this.loading.sendPurchase = false
            this.loaded.sendPurchase = true

            logFeatureUse(ACTION_FILL_SIMA_CART, 'Entire purchase')

            const report = r.data
            if(report.BouncedItems.length > 0) {
              this.$refs.fillCartReport.open(report)
            }
          })
          .catch(e => {
            this.loading.sendPurchase = false
            this.error.sendPurchase = e.message
          })
      },

      handleTogglePaidDelivery() {
        this.loading.togglePaidDelivery = true
        this.loaded.togglePaidDelivery = false
        this.error.togglePaidDelivery = null

        Api.patch(`/Purchases/${this.id}`, {
          IsPaidDelivery: !this.purchase.IsPaidDelivery
        }).then(() => {
          this.loading.togglePaidDelivery = false
          this.loaded.togglePaidDelivery = true
          this.purchase.IsPaidDelivery = !this.purchase.IsPaidDelivery
        })
          .catch(e => {
            this.loading.togglePaidDelivery = false
            this.error.togglePaidDelivery = e.message
          })
      },

      handleChangeSnapshotId() {
        this.loading.snapshots = true
        this.loaded.snapshots = false
        this.error.snapshots = null

        Api.patch(`/Purchases/${this.id}`, {
          SnapshotId: this.purchase.SnapshotId
        }).then(() => {
          this.loading.snapshots = false
          this.loaded.snapshots = true
        }).catch(e => {
          this.loading.snapshots = false
          this.error.snapshots = e.message

          this.purchase.SnapshotId = this.prevSnapshotId
        })
      },

      handleDoSnapshot() {
        this.loading.snapshots = true
        this.loaded.snapshots = false
        this.error.snapshots = null

        Api.post(`/Purchases/${this.id}/Snapshots`).then(r => {
          this.loading.snapshots = false
          this.loaded.snapshots = true

          logFeatureUse(ACTION_SNAPSHOT_CREATE, 'Entire snapshot')

          this.fetchSnapshots().then(() => {
            this.purchase.SnapshotId = r.data.SnapshotId
          })
        }).catch(e => {
          this.loading.snapshots = false
          this.error.snapshots = e.message
        })
      },

      handleOpenClose() {
        this.loading.openClose = true
        this.loaded.openClose = false
        this.error.openClose = null
        const newVal = !this.purchase.IsOpen

        Api.patch(`/Purchases/${this.id}`, {
          IsOpen: newVal
        }).then(() => {
          this.loading.openClose = false
          this.loaded.openClose = true
          this.purchase.IsOpen = newVal
        }).catch(e => {
          this.loading.openClose = false
          this.error.openClose = e.message
        })
      },

      handleInvoiceUpload() {
        const fd = new FormData()
        forEach(this.invoiceFiles, file => fd.append('invoices', file))

        this.loading.invoices = true
        this.loaded.invoices = false
        this.error.invoices = null
        Api.post(`/Purchases/${this.id}/Invoices`, fd, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          // purchase.HasInvoices controls visibility of the list
          this.purchase.HasInvoices = true

          logFeatureUse(ACTION_INVOICE_UPLOAD)

          this.fetchInvoices().then(() => {
            this.invoiceFiles = []
          })
        }).catch(e => {
          this.loading.invoices = false
          this.error.invoices = e.message
        })
      },

      handleInvoiceDelete() {
        this.loading.invoices = true
        this.loaded.invoices = false
        this.error.invoices = null
        Api.delete(`/Purchases/${this.id}/Invoices`).then(() => {
          this.loading.invoices = false
          this.loaded.invoices = true

          this.purchase.HasInvoices = false
          this.invoices = []
        }).catch(e => {
          this.loading.invoices = false
          this.error.invoices = e.message
        })
      },

      handleWholesaleCategories() {
        this.loading.wholesaleCategories = true
        this.loaded.wholesaleCategories = false
        this.error.wholesaleCategories = null
        Api.post(`/Purchases/${this.id}/WholesaleCategories`, {WholesaleCategories: this.purchase.WholesaleCategories}).then(() => {
          this.loading.wholesaleCategories = false
          this.loaded.wholesaleCategories = true

          Api.get(`/Purchases/${this.id}/WholesaleCategories`).then(r => {
            this.purchaseWc = r.data
          }).catch(e => {
            this.error.wholesaleCategories = e.message
          })
        }).catch(e => {
          this.loading.wholesaleCategories = false
          this.error.wholesaleCategories = e.message

          this.purchase.WholesaleCategories = this.prevWholesaleCategories
        })
      },

      updateWholesaleCategory(wc) {
        this.loading.wholesaleCategories = true
        this.loaded.wholesaleCategories = false
        this.error.wholesaleCategories = null
        Api.patch(`/Purchases/${this.id}/WholesaleCategories`, {WholesaleCategory: wc}).then(() => {
          Api.get(`/Purchases/${this.id}/WholesaleCategories`).then(r => {
            this.purchaseWc = r.data
          }).catch(e => {
            this.error.wholesaleCategories = e.message
          })

          this.loading.wholesaleCategories = false
          this.loaded.wholesaleCategories = true
        }).catch(e => {
          this.loading.wholesaleCategories = false
          this.error.wholesaleCategories = e.message

          this.purchase.WholesaleCategories = this.prevWholesaleCategories
        })
      },

      handleDeletedParticipant(pp) {
        this.participants = filter(this.participants, pp2 => pp2.Id !== pp.Id)
      },

      handleToggleAdmin(pp) {
        pp.IsPurchaseAdmin = !pp.IsPurchaseAdmin
        this.$set(this.participants, this.participants.findIndex(pp2 => pp2.Id === pp.Id), pp)
      },

      handleExchangeRate() {
        this.loading.exchangeRate = true
        Api.patch(`/Purchases/${this.id}`, {
          ExchangeRate: this.purchase.ExchangeRate
        }).then(() => {
          this.loading.exchangeRate = false
        }).catch(e => {
          this.loading.exchangeRate = false
          this.error.exchangeRate = e.message
        })
      },

      handleChangeIsHidden(e) {
        const val = e.target.checked
        this.loading.openClose = true
        this.error.openClose = null
        Api.patch(`/Purchases/${this.id}`, {IsHidden: val}).then(() => {
          this.purchase.IsHidden = val

          if (val) {
            logFeatureUse(ACTION_PURCHASE_HIDE)
          }
        }).catch(e => {
          this.error.openClose = e.message
        }).finally(() => {
          this.loading.openClose = false
        })
      },

      refreshParticipants() {
        Api.get(`/Purchases/${this.id}/Metadata`).then(r => {
          this.purchase = r.data

          Api.get(`/Purchases/${this.id}/Participants`).then(r => {
            this.participants = r.data
          }).catch(e => {
            this.error.participants = e.message
          })
        }).catch(e => {
          this.loading.purchase = false
          this.error.purchase = e.message
        })
      },

      handleSettlement(id) {
        this.loading.settlement = true
        this.error.settlement = null
        Api.patch(`/Purchases/${this.id}`, {SettlementId: parseInt(id || 0)}).then(() => { // TODO: Later settlement id will be a string.
          this.loading.settlement = false

          Api.get(`/Purchases/${this.id}/Metadata`).then(r => {
            this.purchase = r.data
          }).catch(e => {
            this.error.purchase = e.message
          })
        }).catch(e => {
          this.loading.settlement = false
          this.error.settlement = e.message
        })
      },

      logSummary() {
        logFeatureUse(ACTION_PARTICIPANTS_SUMMARY)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/variables";
  @import "~bootstrap/scss/mixins";

  .result-msg {
    font-size: $font-size-sm;
    padding: $input-btn-padding-y-sm, $input-btn-padding-x-sm;
    vertical-align: middle;
  }

  .help-description {
    color: $text-muted;
    font-size: $font-size-sm;
    line-height: 1.2;

    margin-bottom: 0;

    + .help-description {
      margin-top: $form-text-margin-top;
    }
  }

  /**
   * Derived from .form-row
   */
  .setting-group {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$form-grid-gutter-width / 2;
    margin-left: -$form-grid-gutter-width / 2;
    margin-bottom: $form-group-margin-bottom;

    > .col-setting {
    }

    > .col-help {
      margin-top: $form-text-margin-top;
    }

    @include media-breakpoint-up(md) {
      > .col-setting {
        @include make-col(6);

        &.wide {
          @include make-col(12);
        }
      }

      > .col-help {
        @include make-col(6);

        margin-top: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      > .col-setting {
        @include make-col(5);

        &.wide {
          @include make-col(10);
        }
      }

      > .col-help {
        @include make-col(5);
      }
    }

    @include media-breakpoint-up(xl) {
      > .col-setting {
        @include make-col(4);

        &.wide {
          @include make-col(8);
        }
      }

      > .col-help {
        @include make-col(4);
      }
    }

    > .col,
    > [class*="col-"] {
      padding-right: $form-grid-gutter-width / 2;
      padding-left: $form-grid-gutter-width / 2;
    }
  }
</style>
